import { RescindOfferRequestBody, nfdContractUpgrade } from 'api/api-client'
import { MutationOptions, usePostTransaction } from './usePostTransaction'

type ContractUpgradeParams = {
  name: string
  body: RescindOfferRequestBody
}

export function usePostContractUpgrade(options: MutationOptions<ContractUpgradeParams> = {}) {
  return usePostTransaction<ContractUpgradeParams>({
    mutationFn: ({ name, body }) => nfdContractUpgrade(name, body),
    ...options
  })
}
