import { useWallet } from '@txnlab/use-wallet-react'
import dayjs from 'dayjs'
import { usePostContractUpgrade } from '@/api/hooks/usePostContractUpgrade'
import { usePostContractV3Upgrade } from '@/api/hooks/usePostContractV3Upgrade'
import { useNameUpdate } from '@/api/hooks/useName'
import { useGetQuote } from '@/api/hooks/useGetQuote'
import useGetVersion from '@/api/hooks/useGetVersion'
import { LATEST_CONTRACT_VERSION_V2, ZERO_ADDRESS } from '@/data/constants'
import { checkBalance } from '@/helpers/checkBalance'
import { formatPrice } from '@/helpers/utilities'
import { meetsMinimumVersion } from '@/helpers/versions'
import useErrorToast from '@/hooks/useErrorToast'
import type { NfdRecord } from '@/types/api'

export function useContractUpgrade(nfd: NfdRecord) {
  const { activeAddress } = useWallet()
  const handleError = useErrorToast()
  const optimisticUpdate = useNameUpdate()

  const { data } = useGetVersion()
  const latestVersion = data?.contractVersion

  const isV2UpgradeRequired = !meetsMinimumVersion(nfd, LATEST_CONTRACT_VERSION_V2)
  const isV3UpgradeRequired = !meetsMinimumVersion(nfd, '3')

  const quoteQuery = useGetQuote({
    name: nfd.name,
    params: {
      buyer: activeAddress || ZERO_ADDRESS
    },
    options: {
      enabled: isV3UpgradeRequired
    }
  })

  const priceOneYear = quoteQuery.data?.price || 0

  const upgradeV2Contract = usePostContractUpgrade({
    toasts: {
      success: `Contract successfully upgraded.`
    },
    onSuccess() {
      const newNfd: NfdRecord = {
        ...nfd,
        properties: {
          ...nfd.properties,
          internal: {
            ...nfd.properties?.internal,
            ver: LATEST_CONTRACT_VERSION_V2
          }
        }
      }

      optimisticUpdate(newNfd)
    },
    onError(error) {
      handleError(error)
    }
  })

  const upgradeContract = usePostContractV3Upgrade({
    toasts: {
      success: `Contract successfully upgraded.`
    },
    onSuccess() {
      const newNfd: NfdRecord = {
        ...nfd,
        properties: {
          ...nfd.properties,
          internal: {
            ...nfd.properties?.internal,
            ver: latestVersion || '3'
          }
        },
        timeExpires: nfd.timeExpires || dayjs().add(1, 'year').toISOString()
      }

      optimisticUpdate(newNfd)
    },
    onError(error) {
      handleError(error)
    }
  })

  const handleUpgradeV2 = async () => {
    try {
      if (!activeAddress) {
        throw new Error('Wallet not connected')
      }

      upgradeV2Contract.mutate({
        name: nfd.name,
        body: {
          sender: activeAddress
        }
      })
    } catch (error) {
      handleError(error)
    }
  }

  const handleUpgradeV3 = async () => {
    try {
      if (!activeAddress) {
        throw new Error('Wallet not connected')
      }

      const balance = await checkBalance(activeAddress, priceOneYear)

      if (!balance.hasSufficientBalance) {
        throw new Error(
          `Insufficient available balance. A minimum available balance of ${formatPrice(
            balance.balanceRequired as number,
            false,
            { maximumFractionDigits: 6 }
          )} ALGO is required to complete this transaction.`
        )
      }

      upgradeContract.mutate({
        name: nfd.name,
        body: {
          sender: activeAddress
        }
      })
    } catch (error) {
      console.error('Error in handleUpgradeV3:', error)
      handleError(error)
    }
  }

  return {
    isV2UpgradeRequired,
    isV3UpgradeRequired,
    handleUpgradeV2,
    handleUpgradeV3,
    priceOneYear
  }
}
