import { RescindOfferRequestBody, nfdContractUpgradeV3 } from 'api/api-client'
import { MutationOptions, usePostTransaction } from './usePostTransaction'

type ContractUpgradeV3Params = {
  name: string
  body: RescindOfferRequestBody
}

export function usePostContractV3Upgrade(options: MutationOptions<ContractUpgradeV3Params> = {}) {
  return usePostTransaction<ContractUpgradeV3Params>({
    mutationFn: ({ name, body }) => nfdContractUpgradeV3(name, body),
    ...options
  })
}
