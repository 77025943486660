import { VersionResponseBody, infoVersion } from 'api/api-client'
import { AxiosError, AxiosRequestConfig } from 'axios'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { captureException } from '@sentry/nextjs'

export const getVersion = async (ssr?: boolean, options?: AxiosRequestConfig) => {
  const { data } = await infoVersion({
    ...(ssr && { headers: { origin: process.env.NEXT_PUBLIC_BASE_URL as string } }),
    ...options
  })

  return data
}

type UseGetVersionArgs = UseQueryOptions<VersionResponseBody, AxiosError>

export default function useGetVersion(options?: UseGetVersionArgs) {
  const result = useQuery<VersionResponseBody, AxiosError>(['version'], () => getVersion(), {
    ...options
  })

  if (result.error) {
    captureException(result.error)
  }

  return result
}
