import Link from 'next/link'
import { HiOutlineExclamation } from 'react-icons/hi'
import type { NfdRecord } from '@/api/api-client'
import { isValidRoot } from '@/helpers/utilities'
import { meetsMinimumVersion } from '@/helpers/versions'

interface VerificationHelpTextProps {
  nfd: NfdRecord
  field: string
  label: string
}

export default function VerificationHelpText({ nfd, field, label }: VerificationHelpTextProps) {
  const isVerified = !!nfd.properties?.verified?.[field]
  const isUserDefined = !!nfd.properties?.userDefined?.[field]

  // Special handling for Bluesky
  if (field === 'blueskydid') {
    if (!isValidRoot(nfd.name)) {
      return (
        <div className="mt-2 flex items-center gap-2 sm:gap-1">
          <HiOutlineExclamation className="w-5 h-5 sm:w-4 sm:h-4 text-gray-600 dark:text-gray-400" />
          <p className="text-sm sm:text-xs text-gray-500">
            Only root NFDs can currently verify a Bluesky account.
          </p>
        </div>
      )
    }

    if (!meetsMinimumVersion(nfd, '3')) {
      return (
        <div className="mt-2 flex items-center gap-2 sm:gap-1">
          <HiOutlineExclamation className="w-5 h-5 sm:w-4 sm:h-4 text-gray-600 dark:text-gray-400" />
          <p className="text-sm sm:text-xs text-gray-500">
            You must{' '}
            <Link
              href={`/manage/${nfd.name}?view=contract`}
              className="text-gray-900 font-medium underline dark:text-brand-500 dark:hover:text-brand-600 dark:no-underline"
            >
              upgrade
            </Link>{' '}
            your NFD to version 3 to verify a Bluesky account.
          </p>
        </div>
      )
    }
  }

  if (isVerified) {
    return (
      <p className="mt-2 text-sm sm:text-xs text-gray-500">
        To change, first{' '}
        <Link
          href={`/manage/${nfd.name}?view=verification`}
          className="text-gray-900 font-medium underline dark:text-brand-500 dark:hover:text-brand-600 dark:no-underline"
        >
          unverify
        </Link>{' '}
        your {label}.
      </p>
    )
  }

  if (isUserDefined) {
    return (
      <p className="mt-2 text-sm sm:text-xs text-gray-500">
        You can{' '}
        <Link
          href={`/manage/${nfd.name}?view=verification`}
          className="text-gray-900 font-medium underline dark:text-brand-500 dark:hover:text-brand-600 dark:no-underline"
        >
          verify
        </Link>{' '}
        your {label} in the Verification section.
      </p>
    )
  }

  return null
}
